import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SidePanel from '../../containers/sidePanel/SidePanel';
import styles from './ModuleSelection.module.css';
import Button from '../../ui/buttons/Button';
import _ from 'lodash';
import {
  updateCrumbs,
  
  sendNotification,
    UnlockWaveClientModuleSelection,    
    UpdateWaveProcessStatus
} from '../../../store/actions/index';
import {
  updateTitle,
  checkUXPrivileges,
  getWaveNameWithStartDate,
  getWaveLink,
} from '../../../utility/utility';
import { UpdateChangesToApprove, getProjectIds } from '../../../store/actions';
import { getRelevantPage } from './ModuleSelection.utility';
import ModalDialog from '../../ui/modal/Modal';
import ConfirmNoCustomQ from './ConfirmNoCustomQ';
import RejectModuleSelection from '../../ui/RejectModuleSelection/RejectModuleSelection';
import RequestClientSelectionUnlock from '../../ui/popupContents/RequestClientSelectionUnlock/RequestClientSelectionUnlock';
import UnlockClientSelection from '../../ui/popupContents/UnlockClientSelection/UnlockClientSelection';

/**
 * @component ModuleSelection
 * @param {String} name The name of the user.
 * @description This component is used to allow users to view, update and save their wave's module selection process, as well as create update and delete ( depending on the user's ux privileges ) local questions for the wave's module selection.
 * 
 * This component is a child of the App component. 
 * 
 * @returns {JSX} A page containing the 'SidePanel' component, as well as a specific component, depending on what the user clicks ( be it a module, or a local question ), as well as their ux privileges.
 * @see './ModuleSelection.utility.js', lines 19-30
 
 */

const ModuleSelection = ({ name }) => {
  const { country, waveId } = useParams();
  const dispatch = useDispatch();
  const customQuestions = useSelector(
    (state) => state.customQuestions.customQuestions
  );
  const wave = useSelector((state) => state.wave.wave);
  const uxPrivileges = useSelector(
    (state) => state.app.user.user.role.uxPrivileges
  );
  const { notifications } = useSelector((store) => store.notifications);
  const { changesToApprove, changesToSave } = useSelector(
    (state) => state.modules
  );
  const projectCountries = useSelector((state) => state.app.projectCountries);

  const [maxLOI, SetMaxLoi] = useState(0);

  const [disableConfirmButton, setDisableConfirmButton] = useState(true);
  const [disableUnlockSelectionButton, setDisableUnlockSelectionButton] =
    useState(false);
  const [disableUnlockRequestButton, setDisableUnlockRequestButton] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showRequestSelectionUnlockModal, setShowRequestSelectionUnlockModal] =
    useState(false);
  const [showUnlockSelectionModal, setShowUnlockSelectionModal] =
    useState(false);
  const [showRejectSelectionModal, setShowRejectSelectionModal] =
    useState(false);
  const [disableRejectButton, setDisableRejectButton] = useState(false);
  const [content, setContent] = useState();
  const [moduleSelectionStatus, setModuleSelectionStatus] = useState('');
  const [IsModuleSelectionPageActive, setIsModuleSelectionPageActive] =
    useState(false);  

  
  /**
   * @method useEffect
   * @memberof ModuleSelection
   * @param {Object} wave Object containing the data for this wave, as well as all countries and their corresponding waves.
   * @description When the 'wave' state variable changes this effect performs the following actions:
   * - Updates the Breadcrumbs componenent and sets it to 'Waves/country name/wave name.
   * - Sets the page title to 'GfK Author | wave name'.
   * - if the 'wave' state variable contains a key 'waveProcesses', as well as a 'Module selection' process, it sets the value of the status of Module selection to that of this wave's Module Selection process, taken from from 'wave/wave/waveProcesses.
   */
  useEffect(() => {
    dispatch(
      updateCrumbs([
        { text: 'Waves', link: '/' },
        { text: country, link: `waves/${country}` },
        { text: getWaveNameWithStartDate(wave), link: null },
      ])
    );
    updateTitle(` | ${wave.name}`);    
    const process = wave?.waveProcesses?.find(
      (process) => process?.process?.name === 'Module selection'
    );
    if (process) {
      setModuleSelectionStatus(process?.status);
    }
  }, [wave,country,dispatch]);

  const [countryId, setCountryId] = useState(null);

  /**
   * @method useEffect
   * @memberof ModuleSelection
   * @description Enables the 'Save' button in the 'SidePanel' component when there are changes made to the module selection. Once the changes are saved, it disables the 'Save' button. This effect is dependent on the 'changesToApprove' parameter.
   *
   * @param {Boolean} changesToApprove Flag that indicates whether the module selection has been finalized and is ready for confirmation. It signifies the user's confirmation to submit their module selection through the 'ConfirmNoCustomQ' component, which occurs after clicking the 'Save' button.
   */
  useEffect(() => {
    setDisableConfirmButton(!changesToApprove);
  }, [changesToApprove]);
  useEffect(() => {
    if (projectCountries) {
      const projectCountry = projectCountries.find(
        (pc) =>
          pc.countryId === wave.countryId && pc.projectId === wave.projectId
      );
      if (projectCountry) {
        setCountryId(projectCountry.countryId);
      }
      if (projectCountry?.maxLOI) {
        SetMaxLoi(projectCountry.maxLOI);
      }
    } else {
      dispatch(getProjectIds());
    }
  }, [dispatch, projectCountries, wave.countryId, wave.projectId]);

  /**
   * @function
   * @memberof ModuleSelection
   * @param {String} clickItemType See './ModuleSelection.utility.js', line 12.
   * @param {String} clickItemId See './ModuleSelection.utility.js', line 13.
   * @description Assigns the appropriate component to the 'content' variable using the 'getRelevantPage' function. The useCallback hook is used here to memoize the function, so that it doesn't get recreated each render unless the 'customQuestions' dependency changes. Using useCallback also fixes a SonarQube code smell.
   */
  const questionclickHandler = useCallback(
    (clikcItemType, clickItemId) =>
      setContent(
        getRelevantPage(
          clikcItemType,
          clickItemId,
          name,
          uxPrivileges,
          customQuestions,
          questionclickHandler,
          moduleSelectionStatus // If the status of the wave's Module Selection is set to 'Finished' the
          // custom question will be displayed in preview mode.
        )
      ),
      [customQuestions, name, uxPrivileges, moduleSelectionStatus]
  );
  const selectedPageTypeHandler = (isModuleSelectionPageActive) => {
    setIsModuleSelectionPageActive(isModuleSelectionPageActive);
  };

  /**
   * @function
   * @memberof ModuleSelection
   * @see './ConfirmNoCustomQ.js', line 8.
   * @description Dispatches 'sendNotification' 'UpdateChangesToApprove(false)' and with the appropriate arguments when triggered in the 'ConfirmNoCustomQ' component. The function is used when the user clicks on 'Submit' in the 'ConfirmNoCustomQ' component to confirm that they are about to submit their final module selection.
   */
  const handleNotifications = () => {
    /* notify approve selection */
    dispatch(
      sendNotification(
        notifications.approveQuestionnaireSelection,
        wave.countryId,
        wave.waveId
      )
    );
      const clientSelectionLocked = true;      
      
    setWaveProcessToInProgressIfPending(wave);
    dispatch(
      UnlockWaveClientModuleSelection(wave.waveId, clientSelectionLocked)
      );
     
      dispatch(UpdateChangesToApprove(false));      
  };

    const setWaveProcessToInProgressIfPending = (wave) => {

      let updatedWaveProcesses = _.cloneDeep(wave.waveProcesses);
      updatedWaveProcesses.map((process) => {
          if (process.process.name === 'Module selection') {
              process.status = 'In progress';
          }
          return process;
      });

      let temporaryWave = {
          ...wave,
          waveProcesses: updatedWaveProcesses,
      };

      dispatch(UpdateWaveProcessStatus(temporaryWave, wave.waveId)); 
  }

  const handleRejectModuleSelection = (userInput) => {
    const inputParams = {
      TextboxMessage: userInput,
      WaveLink: getWaveLink(),
    };

    dispatch(
      sendNotification(
        notifications.rejectQuestionnaireSelection,
        wave.countryId,
        wave.waveId,
        inputParams
      )
    );
    setDisableRejectButton(true);
    const clientSelectionLocked = false;
    dispatch(
      UnlockWaveClientModuleSelection(wave.waveId, clientSelectionLocked)
    );
    dispatch(UpdateChangesToApprove(false));
  };

  /**
   * @function
   * @memberof ModuleSelection
   * @description Shows the 'ModalDialog' component when called. Triggered by clicking on the 'Submit final selection' button.
   */
  const handleShowModal = () => {
    setShowModal(true);
  };

  /**
   * @function
   * @memberof ModuleSelection
   * @see './ConfirmNoCustomQ.js', line 9.
   * @description Hides the 'ModalDialog' component when called.
   */
  const handleClose = () => {
    setShowModal(false);
  };
  const handleShowUnlockSelectionModal = () => {
    setShowUnlockSelectionModal(true);
  };
  const handleUnlockSelection = () => {
    const clientSelectionLocked = false;
    dispatch(
      UnlockWaveClientModuleSelection(wave.waveId, clientSelectionLocked)
    );
    dispatch(
      sendNotification(
        notifications.unlockedModuleSelection,
        wave.countryId,
        wave.waveId
      )
    );
    setDisableUnlockSelectionButton(true);
  };
  const handleShowUnlockSelectionModalClose = () => {
    setShowUnlockSelectionModal(false);
  };

  const handleRequestSelectionModal = () => {
    setShowRequestSelectionUnlockModal(true);
  };
  const handleRequestSelectionUnlockModalClose = () => {
    setShowRequestSelectionUnlockModal(false);
  };
  const handleUnlockSelectionRequest = () => {
    const inputParams = {
      WaveLink: getWaveLink(),
    };
    dispatch(
      sendNotification(
        notifications.unlockClientSelectionRequest,
        wave.countryId,
        wave.waveId,
        inputParams
      )
    );
    setDisableUnlockRequestButton(true);
  };
  const handleShowRejectSelectionModal = () => {
    setShowRejectSelectionModal(true);
  };

  const handleRejectSelectionModalClose = () => {
    setShowRejectSelectionModal(false);
  };
    const isClientSelectionLocked = () => {
        
    return (        
      wave.clientSelectionLocked &&
      checkUXPrivileges(uxPrivileges, 'submitModuleSelection')
    );
  };
  const showUnlockSelectionButton = () => {
    return (
      wave.clientSelectionLocked &&
      checkUXPrivileges(uxPrivileges, 'approveModule')
    );
  };
  const showRequestSelectionUnlockButton = () => {
    return (
      wave.clientSelectionLocked &&
      !checkUXPrivileges(uxPrivileges, 'approveModule')
    );
  };
  const renderSendNotificationUI = () => {
    return (
      <>
        <div className={styles.confirmContainer}>
          {checkUXPrivileges(uxPrivileges, 'submitModuleSelection') && (
            <ModalDialog
              show={showModal}
              content={
                <ConfirmNoCustomQ
                  id="id-submitModuleSelectionDialog"
                  handleApprove={handleNotifications}
                  handleClose={handleClose}
                  noLocalQ={Object.keys(customQuestions).length === 0}
                  changesToSave={changesToSave}
                />
              }
            />
          )}
          {!checkUXPrivileges(uxPrivileges, 'approveModule') && (
            <ModalDialog
              show={showRequestSelectionUnlockModal}
              content={
                <RequestClientSelectionUnlock
                  id="id-RequestClientSelectionUnlockDialog"
                  handleConfirm={handleUnlockSelectionRequest}
                  handleClose={handleRequestSelectionUnlockModalClose}
                />
              }
            />
          )}
          {checkUXPrivileges(uxPrivileges, 'approveModule') && (
            <ModalDialog
              show={showUnlockSelectionModal}
              content={
                <UnlockClientSelection
                  id="id-UnlockClientSelectionDialog"
                  handleConfirm={handleUnlockSelection}
                  handleClose={handleShowUnlockSelectionModalClose}
                />
              }
            />
          )}
          {checkUXPrivileges(uxPrivileges, 'approveModule') && (
            <ModalDialog
              show={showRejectSelectionModal}
              content={
                <RejectModuleSelection
                  id="id-rejectModuleSelectionDialog"
                  handleReject={handleRejectModuleSelection}
                  handleClose={handleRejectSelectionModalClose}
                />
              }
            />
          )}
          {(checkUXPrivileges(uxPrivileges, 'submitModuleSelection') ||
            checkUXPrivileges(uxPrivileges, 'approveModule')) && (
            <div className={styles.buttonMargin}>
              {IsModuleSelectionPageActive &&
                showRequestSelectionUnlockButton() && (
                  <Button
                    type="primary"
                    handleOnClick={handleRequestSelectionModal}
                    disabled={disableUnlockRequestButton}
                  >
                    Request selection unlock
                  </Button>
                )}
              {checkUXPrivileges(uxPrivileges, 'submitModuleSelection') && (
                <Button
                  type="primary"
                  handleOnClick={handleShowModal}
                  disabled={disableConfirmButton}
                >
                  Submit final selection
                </Button>
              )}

              {IsModuleSelectionPageActive && showUnlockSelectionButton() && (
                <Button
                  type="primary"
                  disabled={disableUnlockSelectionButton}
                  handleOnClick={handleShowUnlockSelectionModal}
                >
                  Unlock client selection
                </Button>
              )}
              {IsModuleSelectionPageActive &&
                checkUXPrivileges(uxPrivileges, 'approveModule') && (
                  <Button
                    type="primary"
                    disabled={disableRejectButton}
                    handleOnClick={handleShowRejectSelectionModal}
                  >
                    Reject module selection
                  </Button>
                )}
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className={styles.Page}>
      <SidePanel
        waveId={waveId}
        projectId={wave.projectId}
        maxCustomQ={3}
        maxLoi={maxLOI}
        clickHandler={questionclickHandler}
        selectedPageTypeHandler={selectedPageTypeHandler}
        isReadOnly={
          moduleSelectionStatus === 'Finished' || isClientSelectionLocked()
        }
        moduleSelectionStatus={moduleSelectionStatus}
        countryId={countryId}
      ></SidePanel>
      {content}
      {renderSendNotificationUI()}
    </div>
  );
};

export default ModuleSelection;
