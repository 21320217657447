import * as actionTypes from './actionTypes';
import axios from '../../axiosInstance';

// get notifications
export const getNotificationsStart = () => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_START,
  };
};

export const getNotificationsFail = (error) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_FAIL,
    error: error.message,
  };
};

export const getNotificationsSuccess = (notifications) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
    notifications: notifications,
  };
};

// send notification
export const sendNotificationStart = () => {
  return {
    type: actionTypes.SEND_NOTIFICATION_START,
  };
};

export const sendNotificationFail = (error) => {
  return {
    type: actionTypes.SEND_NOTIFICATION_FAIL,
    error: error.message,
  };
};

export const sendNotificationSuccess = () => {
  return {
    type: actionTypes.SEND_NOTIFICATION_SUCCESS,
  };
};

// api calls
export const getNotifications = () => {
  return (dispatch) => {
    dispatch(getNotificationsStart());
    axios
      .get(`Notifications`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        }
        const result = res.data;
        const notifications = Object.fromEntries(
          result.map((n) => [n.key, n.notificationId])
        );
        dispatch(getNotificationsSuccess(notifications));
      })
      .catch((error) => {
        dispatch(getNotificationsFail(error));
      });
  };
};

export const sendNotification = (notificationId, countryId, waveId = -1, inputParameters = null) => {
  return (dispatch) => {
    dispatch(sendNotificationStart());
    axios      
        .post(`Notifications/${notificationId}/${countryId}?waveId=${waveId}`, inputParameters)
      .then((res) => {
        console.log(res);
        dispatch(sendNotificationSuccess());
      })
      .catch((err) => {
        dispatch(sendNotificationFail(err));
      });
  };
};
