import { useSelector } from "react-redux";

import { checkUXPrivileges } from "../../../utility/utility";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function EditWaveGuard({ children, navigateIfFalse }) {
    const { uxPrivileges } = useSelector((state) => state.app.user.user.role);
    const navigate = useNavigate();
    const [canActivateRoute, setActivateRoute] = useState(false);

    useEffect(() => {
        if (!checkUXPrivileges(uxPrivileges, "approveModule")) {
            navigate(navigateIfFalse);
        } else {
            setActivateRoute(true);
        }
    }, [uxPrivileges]);

    return canActivateRoute ? children : <div></div>;
}
