import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUserData } from './store/actions';
import CreateEditWave from './components/containers/createEditWave/CreateEditWave';
import ModuleSelection from './components/pages/moduleSelection/ModuleSelection';
import WaveSelection from './components/pages/waveSelection/WaveSelection';
import QuestionnairePreview from './components/containers/questionnairePreview/QuestionnairePreview';
import styles from './App.module.css';
import { PageLayout } from './components/PageLayout';

import Button from 'react-bootstrap/Button';

import { GetCountriesWaves } from './store/actions/wave';
import { fetchMessageList } from './store/actions/message';
import { getNotifications } from './store/actions/notification';

import ProtectedRoute from './components/Routes/ProtectedRoute';

function App() {
  const dispatch = useDispatch();
  const { isAuthenticated, userName } = useSelector((state) => state.app.user);

  const login = () => {
    window.location.href = '/api/auth/login';
  };

  const logout = () => {
    window.location.href = '/api/auth/logout';
  };
  const initialLoad = () => {
    dispatch(fetchMessageList());
    dispatch(getUserData());
    dispatch(GetCountriesWaves());
    dispatch(getNotifications());
  };

  useEffect(() => {
      initialLoad();
  }, []);

    function LoginPage({ login }) {
        return !isAuthenticated ? (
            <div className={styles.unAuthenticatedBody}>
                <p>You are not signed in! Please sign in.</p>
                <Button variant="secondary" onClick={login} className={styles.signin}>
                    Sign In
                </Button>
            </div>
        ) : (
            <Navigate to={"/waves"} />
        );
    }

  return (
    <>
      {isAuthenticated ? (
        <>
          <Router>
            <Routes>
              <Route
                path="/"
                element={<PageLayout userName={userName} logout={logout} />}
              >
                <Route index element={<Navigate to="waves" replace />} />
                <Route path="waves" element={<WaveSelection />} />
                <Route path="waves/:country" element={<WaveSelection />} />
                <Route
                  path="waves/:country/:waveId"
                  element={
                      <ProtectedRoute meta={{ type: 'ModuleSelection' }} navigateIfFalse={"/waves"}> 
                          <ModuleSelection name={userName} />
                      </ProtectedRoute>
                  }
                />
                <Route
                  path="waves/:country/createWave"
                    element={
                        <ProtectedRoute meta={{ type: "CreateAndEditWave" }} navigateIfFalse={"/waves"}>
                            <CreateEditWave name={userName} autofillProcess={true} />
                        </ProtectedRoute>
                    }
                />
                <Route
                  path="preview/:country/:waveId"
                  element={<QuestionnairePreview />}
                />
                <Route
                  path="preview/:country/:projectId/:modules"
                  element={<QuestionnairePreview />}
                />
                <Route
                  path="waves/:country/:waveId/editWave"
                  element={
                      <ProtectedRoute
                          meta={{ type: "CreateAndEditWave" }} navigateIfFalse={"/waves"}>
                          <CreateEditWave name={userName} autofillProcess={false} />
                      </ProtectedRoute>
                  }
                />
              </Route>
            </Routes>
          </Router>
        </>
      ) : (
        <>
                      <LoginPage login={login} />
        </>
      )}
    </>
  );
}

export default App;
