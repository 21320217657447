import ModuleSelectGuard from "./guardComponents/ModuleSelectGuard.js";
import EditWaveGuard from "./guardComponents/EditWaveGuard.js";

const ProtectedRoute = ({ children, meta, navigateIfFalse }) => {   

    function renderSwitch(type) {
        switch (type) {
            case "ModuleSelection":
                return (
                    <ModuleSelectGuard children={children} navigateIfFalse={navigateIfFalse}/>
                );
            case "CreateAndEditWave":
                return (
                    <EditWaveGuard children={children} navigateIfFalse={navigateIfFalse}/>
                );
            default:
                return <div></div>;
        }
    }
    return <>{renderSwitch(meta.type)}</>;
};
export default ProtectedRoute;
