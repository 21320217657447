import { Document, Packer, Paragraph, HeadingLevel, TextRun, ExternalHyperlink, AlignmentType, LevelFormat, convertInchesToTwip } from 'docx';
import { saveAs } from 'file-saver';
import { GetFormattedLocalQuestionName, getStatus, getWaveNameWithStartDate} from '../../../utility/utility';
import { cloneDeep as _cloneDeep } from 'lodash';
/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} selectedModules Array containing all modules that have been selected for this wave.
 * @param {Array} customQuestions Array containing all local questions for this wave.
 * @param {Boolean} isCountrySpecificQuestionsDetected Boolean for if a wave contains country specific questions.
 * @description The function accepts all data required to generate the preview and populates the 'moduleDocxContent' by calling  buildModulesDocxContent method and localQuestoinDocxContent by calling buildCustomQuestionDocxContent method. Once the function finished its execution. This method returns all the data for the wave's module selection ( including if it has country specific data, the modules and their data, as well as the local questions).
 */
const buildDocxPageContents = (
  selectedModules,
  customQuestions,
  isCountrySpecificQuestionsDetected
) => {
 const moduleDocxContent = buildModulesDocxContent(_cloneDeep(selectedModules),isCountrySpecificQuestionsDetected);
 const localQuestoinDocxContent = buildCustomQuestionDocxContent(_cloneDeep(customQuestions));
 return [moduleDocxContent, localQuestoinDocxContent];
};

const emptyLine = new Paragraph({
  text: " ",
});

const listsRef = [];

const createNewRef = (refName) => {
  listsRef.push({
  reference: refName,
  levels: [
      {
        level: 0,
        format: LevelFormat.Decimal,
        text: "%1",
        alignment: AlignmentType.START,
        style: {
            paragraph: {
                indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.25) },
            },
        },
      },{
        level: 1,
        format: LevelFormat.Decimal,
        text: "%2",
        alignment: AlignmentType.START,
        style: {
            paragraph: {
                indent: { left: convertInchesToTwip(0.75), hanging: convertInchesToTwip(0.25)},
            },
        },
      },{
        level: 2,
        format: LevelFormat.Decimal,
        text: "%3",
        alignment: AlignmentType.START,
        style: {
            paragraph: {
                indent: { left: convertInchesToTwip(1), hanging: convertInchesToTwip(0.25)},
            },
        },
      },{
        level: 3,
        format: LevelFormat.Decimal,
        text: "%4",
        alignment: AlignmentType.START,
        style: {
            paragraph: {
                indent: { left: convertInchesToTwip(1.25), hanging: convertInchesToTwip(0.25)},
            },
        },
      },{
        level: 4,
        format: LevelFormat.Decimal,
        text: "%5",
        alignment: AlignmentType.START,
        style: {
            paragraph: {
                indent: { left: convertInchesToTwip(1.5), hanging: convertInchesToTwip(0.25)},
            },
        },
      }
  ],
})}

const FONT_STYLE = { font: 'Lato' };
const PADDING_TEXTRUN = new TextRun('	');

const createParagraph = ({text, bold, size, heading, color, italics, underline}) => {
  return new Paragraph({
    children:[
      new TextRun({
        text: text,
        bold: bold,
        size: size,
        color: color,
        italics: italics,
        underline: underline,
        font: 'Lato'
      })
    ],
    heading: heading ? HeadingLevel[heading] : null
  })
} 
const createComplexParagraph = (arr) => {
  console.log(arr)
  const objList = []
  arr.forEach(el => {
    if (el.hasOwnProperty('href')){
      objList.push(new ExternalHyperlink({
        children: [
            new TextRun({
              text: el?.text,
              bold: el?.bold,
              size: el?.size,
              color: el?.color,
              italics: el?.italics,
              underline: el?.underline,
              style: 'Hyperlink',
              font: 'Lato'
            }),
        ],
        link: el.href,
      }))
    }
    else{
      objList.push(
        new TextRun({
          text: el?.text,
          bold: el?.bold,
          size: el?.size,
          color: el?.color,
          italics: el?.italics,
          underline: el?.underline,
          font: 'Lato'
        })
      )
    }
  })
  return new Paragraph({
    children:objList,
    heading: arr[0].hasOwnProperty("heading") ? HeadingLevel[arr[0].heading] : null
  })
} 

const createListItem = ({text, level, type}, arr) => {
  let name = "ref" +listsRef.length
  const objList = []
  arr.forEach(el => {
    if (el.hasOwnProperty('href')){
      objList.push(new ExternalHyperlink({
        children: [
            new TextRun({
              text: el?.text,
              bold: el?.bold,
              size: el?.size,
              color: el?.color,
              italics: el?.italics,
              underline: el?.underline,
              style: 'Hyperlink',
              font: 'Lato'
            }),
        ],
        link: el.href,
      }))
    }
    else{
      objList.push(
        new TextRun({
          text: el?.text,
          bold: el?.bold,
          size: el?.size,
          color: el?.color,
          italics: el?.italics,
          underline: el?.underline,
          font: 'Lato'
        })
      )
    }
  })
  return new Paragraph({
    children:objList,
    bullet: type === 'ul'? 
      {
        level: level
      } : null,
    numbering: type === 'ol'?
      {
        reference: name,
        level: level
      } : null
  })
}

const qTextPara = createParagraph( {text:"Question text:",bold:true})

/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} selectedModules Array containing all modules that have been selected for this wave.
 * @param {Boolean} isCountrySpecificQuestionsDetected Boolean for if a wave contains country specific questions.
 * @description The function generates content related to module questions.
 */
const buildModulesDocxContent = (
  selectedModules,
  isCountrySpecificQuestionsDetected
) => {
  const moduleDocxContent = [];
  moduleDocxContent.push(
        createParagraph({text:"Modules", size: 64, heading: "HEADING_1"}),
        emptyLine,
        !isCountrySpecificQuestionsDetected && createParagraph({text:"No country specific data detected for this country", color: "bc1c0e"})
    );

  // Iterate each module to turn the contents of each module question into docx elements
  selectedModules.forEach((module) => {
    moduleDocxContent.push(
        emptyLine,
        createParagraph( {text:module.name, size: 44, heading:"HEADING_2"}),
        createParagraph( {text:module.description, bold: true})
      );

    // Adds Module Questions into the variable that will be used to render the document
    const questionsDocxContent = buildModuleQuestionsDocxContent(module?.questions);

    //Add each question content to its module content
    questionsDocxContent.forEach((questionContent) => {
      moduleDocxContent.push(emptyLine, 
        ...questionContent);
    });
  });

  const modulesDocx = { children: [...moduleDocxContent] };
  return modulesDocx;
};

/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} questions Array containing all questions related to a module.
* @description The function generates content related to questions and returns as list in doc format.
 */
const buildModuleQuestionsDocxContent = (questions) => {
  const questionsContent = questions.map((moduleQuestion) => {
    const qnName = createParagraph({text: moduleQuestion.name, size: 28, heading: "HEADING_4"})

    const qnBase = createParagraph({text:`Base: ${moduleQuestion.base ? moduleQuestion.base : "All respondents"}`})
    //if no scripting instruction what we should do
    const qnScripting = createParagraph( {text:`Scripting instructions: ${moduleQuestion.scriptingInstructions}`})

    const qnAddRules = createParagraph( {text:`Additional rules: ${moduleQuestion.additionalRules ? moduleQuestion.additionalRules : "No additional rules"}`})
    

    //Variable code as Question name
    const qnVariableCode = createComplexParagraph([{text: "Question name: ", bold: true}, {text: moduleQuestion.variableCode}])

    const moduleQuestionContent = [
      emptyLine,
      qnName,
      qnBase,
      qnScripting,
      qnAddRules,
      emptyLine,
      qnVariableCode,
    ];
    //Get Questiontext and Answer content
    const qnQuestionsTexts = buildQuestionTextAndAnswerContent(
      moduleQuestion.questionTextFields
    );

    //Append Questiontext and Answer content after its Question details
    qnQuestionsTexts.forEach((questionTextFieldContent) => {
      moduleQuestionContent.push(...questionTextFieldContent);
    });

    return moduleQuestionContent;
  });

  return questionsContent;
};
/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} questionTextFields Array containing all questions related to a module.
 * @description The function generates content related to question text answer options and returns as list in doc format.
 */
const buildQuestionTextAndAnswerContent = (questionTextFields) => {
  const questionTextsContent = questionTextFields.map((questionTextField) => {
    const questionTextAnswerOptionsContent = [
      emptyLine,
      qTextPara,
      createParagraph({text: questionTextField.questionText})
    ];
    if (questionTextField.answerText) {
      questionTextAnswerOptionsContent.push(
        createParagraph({text: "Answers: ", bold: true})
      );
      questionTextAnswerOptionsContent.push(
        createParagraph({text: questionTextField.answerText})
      );
    }
    const answerRowsCount = questionTextField?.answerRowList?.length ?? 0;
    const answerColsCount = questionTextField?.answerColList?.length ?? 0;
    if (answerRowsCount > 0) {
      if (answerColsCount > 0 || questionTextField?.answerText) {
        // if answer text is not null or the answers are structured in rows and columns adds the text "Answer Rows:" before them
        questionTextAnswerOptionsContent.push(
          createParagraph({text: "Answer Rows:", bold: true})
        );
      } else {
        // if answer text value is null and the answers are structured only in rows adds the text "Answers:" before them
        questionTextAnswerOptionsContent.push(
          createParagraph({text: "Answers:", bold: true})
        );
      }
      questionTextField.answerRowList
        .sort((a, b) => a.sequence - b.sequence)
        .forEach((answerRow) => {
          // adds answerRows content for each question
          questionTextAnswerOptionsContent.push(
            createParagraph({text: `${answerRow.code}. ${answerRow.text}`})
          );
        });
    }
    
    if (answerColsCount > 0) {
        // If there is options in cols, add "Answer Columns:" before them
  
        questionTextAnswerOptionsContent.push(
          createParagraph({text: "Answer Columns:", bold: true})
        );
      }
    questionTextField?.answerColList
      ?.sort((a, b) => a.sequence - b.sequence)
      ?.forEach((answerCol) => {
        // adds answerRows content for each question
        questionTextAnswerOptionsContent.push(
          createParagraph({text: `${answerCol.code}. ${answerCol.text}`})
        );
      });
    return questionTextAnswerOptionsContent;
  });
  return questionTextsContent;
};

/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} customQuestions Array containing all local questions for the wave.
 * @description The function generates content related to wave local questions and returns the list in doc format.
 */
const buildCustomQuestionDocxContent = (customQuestions) => {
  const customQns = customQuestions.map((customQuestion) => {
    return {
      customNamePrefix: customQuestion.customNamePrefix ?? "",
      customQuestionName: customQuestion.question?.name,
      customQuestionStatus: customQuestion.customQuestionStatus.status,
      isLegalApproved: customQuestion.isLegalApproved
        ? "Question and translations have been legally approved"
        : "Question and translations have not been legally approved",
      variableCode: customQuestion.question.variableCode,
      questionType: customQuestion.question.questionType?.name,
      base: customQuestion.question.base,
      scriptingInstruction: customQuestion.scriptingInstruction,
      dpInstructions: customQuestion.dpInstructions,
      questionTextFields: customQuestion.question?.questionTextFields?.map(
        (qtf) => {
          return {
            questionText: qtf.questionText,
            questionAnswer: qtf.answerText,
            language: qtf.language.name,
            isTranslationApproved: qtf.isTranslationApproved
              ? "Translation has been approved."
              : "Translation has not been approved.",
          };
        }
      ),
    };
  });


  const customQnDocxContent = [];
  customQns.forEach((customQn, index) => {
    const customQnName = createParagraph({text: `${index + 1}. ${GetFormattedLocalQuestionName(customQn?.customNamePrefix, customQn.customQuestionName)}`, size: 28, heading: "HEADING_4"})

    const status = getStatus(customQn.customQuestionStatus);
    const customQnStatus = createParagraph({text: `Question status: ${status}`, bold: true})
    const customQnsTextFields = buildLocalQuestionTextFieldContent(customQn.questionTextFields);
    const customQnsVarCode = createComplexParagraph([{text: "Variable code: ", bold: true}, {text: customQn.variableCode && `${customQn.variableCode?.replace(/<[^>]*>/g,"")}`}])
    const customQnsType = createComplexParagraph([{text: "Question type: ", bold: true}, {text: customQn.questionType && `${customQn.questionType?.replace(/<[^>]*>/g, "")}`}])
    const customQnsBase = createComplexParagraph([{text: "Base: ", bold: true}, {text: customQn.base && `${customQn.base?.replace(/<[^>]*>/g, "")}`}])
    const customQnsScriptingInst = createComplexParagraph([{text: "Scripting instructions: ", bold: true}, {text: customQn.scriptingInstruction && `${customQn.scriptingInstruction?.replace(/<[^>]*>/g, "")}`}])
    const customDPTables = buildLocalQuestionDPInstructionsContent(customQn.dpInstructions);
    let customQnsLegalApproved = createParagraph({text: customQn.isLegalApproved, bold: true})

    customQnDocxContent.push(
      emptyLine,
      customQnName,
      customQnStatus,
      emptyLine,
      customQnsVarCode,
      customQnsType,
      customQnsBase
    );

    customQnsTextFields.forEach((questionTextFieldQuesAns) => {
      customQnDocxContent.push(emptyLine, ...questionTextFieldQuesAns);
    });

    customQnDocxContent.push(
      emptyLine,
      customQnsScriptingInst,
      emptyLine,
      createParagraph({text: "DP Table Instructions", bold: true})
    );

    customDPTables.forEach((dpInstructions) => {
      customQnDocxContent.push(...dpInstructions);
    });

    customQnDocxContent.push(emptyLine, customQnsLegalApproved);
  });

  // Adds Local Questions into the variable that will be used to render the document
  const localQuestionsContent = {
    children: [
      customQuestions.length > 0 &&
        createParagraph({text: "Local Questions", size: 64, heading:"HEADING_1"}),
      ...customQnDocxContent,
    ],
  };
  return localQuestionsContent;
};

const splitIntoParagraphs = (htmlString) => {
  // Create a temporary DOM element to parse the HTML string
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  // Array to hold the split elements
  const elementsArray = [];

  // Function to recursively process child nodes
  function processNode(node) {
      if (node.nodeType === Node.ELEMENT_NODE) {
          const tagName = node.tagName.toLowerCase();
          if (['p', 'ol', 'ul', 'h1', 'h2', 'h3', 'h4', 'h5', 'blockquote'].includes(tagName)) {
            elementsArray.push({ htmlString: node.outerHTML, type: tagName });
          } else {
              Array.from(node.childNodes).forEach(child => processNode(child));
          }
      }
  }

  // Start processing the child nodes of the temporary div
  Array.from(tempDiv.childNodes).forEach(child => processNode(child));

  return elementsArray;
}

const parseStyledText = (htmlString) => {
  // Create a temporary DOM element to parse the HTML string
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  // Array to hold the styled text objects
  const styledTextArray = [];

  // Function to extract styles from a node
  function extractStyles(node) {
      const styles = {};
      if (node.nodeType === Node.ELEMENT_NODE) {
          if (node.closest('strong') || node.style.fontWeight === 'bold') {
              styles.bold = true;
          }
          if (node.closest('em') || node.closest('i') || node.style.fontStyle === 'italic') {
              styles.italics = true;
          }
          if (node.closest('span[style*="text-decoration: underline"]') || node.closest('u') || node.style.textDecoration === 'underline') {
              styles.underline = {};
          }
          if (node.style.color) {
              styles.color = node.style.color;
          }
      }
      return styles;
  }

  function checkForLink(node){
    const link = {}
    if(node.tagName === 'A' || node.tagName === 'a' || node.closest('a')){
        link.href = node.getAttribute('href') || node.closest('a').getAttribute('href');
        console.log(link.href);
    }
    return link
  }

  // Function to recursively process child nodes
  function processNode(node) {
      if (node.nodeType === Node.TEXT_NODE && node.nodeValue) {
          const parentStyles = extractStyles(node.parentNode);
          const link = checkForLink(node.parentNode)
          styledTextArray.push({ text: node.nodeValue, ...parentStyles, ...link });
      } else if (node.nodeType === Node.ELEMENT_NODE) {
          Array.from(node.childNodes).forEach(child => processNode(child));
      }
  }

  // Start processing the child nodes of the temporary div
  Array.from(tempDiv.childNodes).forEach(child => processNode(child));
  console.log(styledTextArray)
  return styledTextArray;
}

const parseNestedList = (htmlString) => {
    // Create a temporary DOM element to parse the HTML string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    // Array to hold the list items with their levels and types
    const listItems = [];

    // Recursive function to process list items
    function processListItems(node, level, type) {
        if (node.nodeType === Node.ELEMENT_NODE) {
            if (node.tagName.toLowerCase() === 'li') {
                // Extract the text content of the list item
                const text = Array.from(node.childNodes)
                    .filter(child => child.nodeType === Node.TEXT_NODE || child.nodeType === Node.ELEMENT_NODE && child.tagName.toLowerCase() !== 'ol' && child.tagName.toLowerCase() !== 'ul')
                    .map(child => child.nodeValue || child.outerHTML)
                    .join('');
                if (text) {
                    listItems.push({ text: text, level: level, type: type });
                }
                // Process nested lists
                Array.from(node.childNodes).forEach(child => {
                    if (child.nodeType === Node.ELEMENT_NODE && (child.tagName.toLowerCase() === 'ol' || child.tagName.toLowerCase() === 'ul')) {
                        Array.from(child.childNodes).forEach(grandChild => processListItems(grandChild, level + 1, child.tagName.toLowerCase()));
                    }
                });
            } else if (node.tagName.toLowerCase() === 'ol' || node.tagName.toLowerCase() === 'ul') {
                Array.from(node.childNodes).forEach(listItem => processListItems(listItem, level, node.tagName.toLowerCase()));
            }
        }
    }

    // Start processing the child nodes of the temporary div
    Array.from(tempDiv.childNodes).forEach(child => {
        if (child.nodeType === Node.ELEMENT_NODE && (child.tagName.toLowerCase() === 'ol' || child.tagName.toLowerCase() === 'ul')) {
            Array.from(child.childNodes).forEach(listItem => processListItems(listItem, 1, child.tagName.toLowerCase()));
        }
    });

    return listItems;
}


const htmlStyledText = (styledText) => {
  const paragraphArray = splitIntoParagraphs(styledText)
  let result = [];
  paragraphArray.forEach((el) => {
    if (el.type === "ul" || el.type === "ol"){
      const listArr = parseNestedList(el.htmlString)
      console.log(listArr)
      createNewRef("ref" +listsRef.length)
      listArr.forEach(item => {
        result.push(createListItem(item,parseStyledText(item.text)))
      })
    } 
    else{
      let styledArr = parseStyledText(el.htmlString)
      if(el.type.indexOf('h' === 0) && el.type.length === 2){
        styledArr[0].heading = "HEADING_"+ el.type[1]
      }
      result.push(createComplexParagraph(styledArr))
    }
  })
  return result
}


/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} questionTextFields Array containing all answer text and answer options for the local question.
 * @description The function generates content related to local questions answer options and returns the list in doc format.
 */
const buildLocalQuestionTextFieldContent = (questionTextFields) => {
  const questionTextFieldsContent = questionTextFields.map(
    (questionTextField) => {
      return [
        createComplexParagraph([
          {text: "Question text: ", bold: true}, 
          questionTextField.language
            ? {text: ` - ${questionTextField.language}:`, bold: true}
            : {text: ":", bold: true},
          //...htmlStyledText(questionTextField.questionText)
          //{text: ` ${questionTextField.questionText.replace(/<[^>]*>/g,"")}`}
        ]),
        ...htmlStyledText(questionTextField.questionText),
        createParagraph({text: "Answer: ", bold: true}),
        ...htmlStyledText(questionTextField.questionAnswer),
        createParagraph({text: questionTextField.isTranslationApproved})
      ];
    }
  );
  return questionTextFieldsContent;
};
/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} dpInstructions Array containing all DP table instructions for the local question.
 * @description The function generates content related to local questions DP table instructions and returns the list in doc format.
 */
const buildLocalQuestionDPInstructionsContent = (dpInstructions) => {
  const dpInstructionsContent = dpInstructions?.map(
    (dpInstruction, index) => {
      return [
        emptyLine,
        createParagraph({text: `${"Instructions Table " + (index + 1) + ":"}`, bold:true}),
        createComplexParagraph([
          {text: "Question Name: ", bold: true}, 
          {text: dpInstruction?.tableName && `${dpInstruction?.tableName.replace(/<[^>]*>/g, "")}`,
          }
        ]),
        createComplexParagraph([
          {text: "Short Title: ", bold: true}, 
          {text: dpInstruction?.tableTitle && `${dpInstruction?.tableTitle.replace(/<[^>]*>/g, "")}`,
          }
        ]),
        createComplexParagraph([
          {text: "Base / Filter: ", bold: true}, 
          {text: dpInstruction?.tableBase && `${dpInstruction?.tableBase.replace(/<[^>]*>/g, "")}`
          }
        ]),
        createComplexParagraph([
          {text: "Instructions: ", bold: true}, 
          {text: dpInstruction?.dpInstruction && `${dpInstruction?.dpInstruction.replace(/<[^>]*>/g, "")}`
          }
        ]),
        createComplexParagraph([
          {text: "Additional Information: ", bold: true}, 
          {text: dpInstruction?.dpAdditionalInformation && `${dpInstruction?.dpAdditionalInformation.replace(/<[^>]*>/g, "")}`
          }
        ])
      ];
    }
  );
  return dpInstructionsContent;
};
/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Object} doc Document object containing the contents of the file that will be saved.
 * @param {String} fileName Name of the file that will be saved.
 *
 * @description This function accepts a Document object and a file name as arguments, and based on them it creates and downloads a .docx file.
 */
const saveDocumentToFile = (doc, fileName) => {
  // Create a mime type that will associate the new file with Microsoft Word
  const mimeType =
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  // Create a Blob containing the Document instance and the mimeType
  Packer.toBlob(doc).then((blob) => {
    const docblob = blob.slice(0, blob.size, mimeType);
    // Save the file using saveAs from the file-saver package
    saveAs(docblob, fileName);
  });
};

/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} selectedModules Array containing all modules that have been selected for this wave.
 * @param {Array} customQuestions Array containing all local questions for this wave.
 * @param {Boolean} isCountrySpecificQuestionsDetected Boolean for if a wave contains country specific questions.
 * @param {Object} wave Object containing the current wave in the store.
 *
 * @description This function builds the page contents of the .docx file that will be generated ( using the function 'buildDocxPageContents' ), creates a new 'Document' and assigns the created page contents to it. After building the content and assigning it to the document it saves the file as a .docx with the name being the name of the wave using the function 'saveDocumentToFile'.
 */
export const generateWordDocument = (
  selectedModules,
  waveName,
  incomingCustomQuestions,
  incomingIsCountrySpecificQuestionsDetected,
  isDownloadAll
) => {
  let customQuestions = incomingCustomQuestions || [];
  let isCountrySpecificQuestionsDetected =
    incomingIsCountrySpecificQuestionsDetected || false;

  const downloadPreviewDocxContent = buildDocxPageContents(
    selectedModules,
    customQuestions,
    isCountrySpecificQuestionsDetected
  );
  

  let doc = new Document({
    numbering: {
      config: listsRef,
    },
    sections: downloadPreviewDocxContent
  });
  saveDocumentToFile(doc, `${waveName}.docx`);
};
