import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const ModuleSelectGuard = ({ children, navigateIfFalse }) => {
    const { country, waveId } = useParams();
    const countryList = useSelector((state) => state?.wave?.countries);
    const navigate = useNavigate();
    const [canActivateRoute, setActivateRoute] = useState(false);

    

    const getWaveProcessStatus = () => {
        if (countryList?.length > 0) {
            const countryObj = countryList.find((item) => item.name === country);
            const wave = countryObj?.waves?.find(
                (item) => item.waveId === parseInt(waveId)
            );
            const process = wave?.waveProcesses?.find(
                (process) => process.process.name === "Module selection"
            );            
            return process?.status ?? undefined;
        }
        return null;
    };      

    useEffect(() => {
        const updatedStatus = getWaveProcessStatus();
        
        switch (updatedStatus) {
            
            case "Finished":                
                navigate(`/preview/${country}/${waveId}`);              
                break;
            case null:
                setActivateRoute(false);
                break;
            case undefined:
                navigate(navigateIfFalse);               
                break;
            default:
                setActivateRoute(true);
                break;
        }
    }, [countryList]);
    
    return canActivateRoute ? children : <div></div>;
};

export default ModuleSelectGuard;
