import { useState } from 'react';
import styles from './MultiSelectDropdown.module.css';
import PropTypes from 'prop-types';
import Checkbox from '../checkbox/Checkbox';
import useComponentVisible from './useComponentVisible';
import iconUp from '../../../assets/svg/accordion_up.svg';
import iconDown from '../../../assets/svg/accordion_down.svg';
import { getStyling } from '../../../utility/utility';

function MultiSelectDropdown({
  dropdownTitle,
  content,
  handleCheck,
  additionalStyling,
}) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [active, setActive] = useState(-1);

  const toggleList = () => {
    setIsComponentVisible((prevCheck) => !prevCheck);
  };

  const hoverStart = (id) => {
    setActive(id);
  };

  const hoverEnd = () => {
    setActive(-1);
  };

  return (
    <div
      data-testid="id-multiSelectDropdown"
      className={ `${styles.DropDownContainer} ${isComponentVisible ? styles.Expanded : ''} ${getStyling(styles, additionalStyling)}`}
      ref={ref}
    >
      <div
        data-testid="id-dropdownTitle"
        className={styles.DropDownTitle}
        onClick={() => toggleList()}
      >
        {dropdownTitle}{' '}
        {isComponentVisible ? (
          <img src={iconUp} alt="-" />
        ) : (
          <img src={iconDown} alt="+" />
        )}
      </div>
      <div
        className={`${styles.ListContainer} ${
          isComponentVisible ? '' : styles.Hidden
        }`}
      >
        {content.map((el) => {
          return (
            <div
              key={el.id}
              className={`${styles.ListItem} ${
                active === el.id ? styles.Hovered : ''
              }`}
              onMouseEnter={() => hoverStart(el.id)}
              onMouseLeave={() => hoverEnd()}
            >
              <Checkbox
                key={el.id}
                label={el.name}
                mandatory={false}
                active={active === el.id}
                id={el.id}
                checked={el.selected}
                changed={(checked, id) => handleCheck(id, checked)}
                additionalStyling={['NoPadding']}
              ></Checkbox>
            </div>
          );
        })}
      </div>
    </div>
  );
}

MultiSelectDropdown.propTypes = {
  dropdownTitle: PropTypes.string.isRequired,
  content: PropTypes.array.isRequired,
  handleCheck: PropTypes.func.isRequired,
};

export default MultiSelectDropdown;
